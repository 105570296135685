exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-agenda-js": () => import("./../../../src/templates/agenda.js" /* webpackChunkName: "component---src-templates-agenda-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-customer-case-js": () => import("./../../../src/templates/customer-case.js" /* webpackChunkName: "component---src-templates-customer-case-js" */),
  "component---src-templates-customer-cases-js": () => import("./../../../src/templates/customer-cases.js" /* webpackChunkName: "component---src-templates-customer-cases-js" */),
  "component---src-templates-educations-detail-js": () => import("./../../../src/templates/educations-detail.js" /* webpackChunkName: "component---src-templates-educations-detail-js" */),
  "component---src-templates-educations-js": () => import("./../../../src/templates/educations.js" /* webpackChunkName: "component---src-templates-educations-js" */),
  "component---src-templates-educations-type-js": () => import("./../../../src/templates/educations-type.js" /* webpackChunkName: "component---src-templates-educations-type-js" */),
  "component---src-templates-klacht-js": () => import("./../../../src/templates/klacht.js" /* webpackChunkName: "component---src-templates-klacht-js" */),
  "component---src-templates-news-overview-js": () => import("./../../../src/templates/news-overview.js" /* webpackChunkName: "component---src-templates-news-overview-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-privacy-js": () => import("./../../../src/templates/privacy.js" /* webpackChunkName: "component---src-templates-privacy-js" */),
  "component---src-templates-search-page-js": () => import("./../../../src/templates/search-page.js" /* webpackChunkName: "component---src-templates-search-page-js" */),
  "component---src-templates-study-choice-js": () => import("./../../../src/templates/study-choice.js" /* webpackChunkName: "component---src-templates-study-choice-js" */),
  "component---src-templates-study-choice-meeting-js": () => import("./../../../src/templates/study-choice-meeting.js" /* webpackChunkName: "component---src-templates-study-choice-meeting-js" */),
  "component---src-templates-vacancies-js": () => import("./../../../src/templates/vacancies.js" /* webpackChunkName: "component---src-templates-vacancies-js" */)
}

